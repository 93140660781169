/* eslint-disable no-param-reassign */

import angular from 'angular';
import '@uirouter/core';
import '@uirouter/angularjs';
import 'angular-animate';
import 'angular-aria';
import 'angular-messages';
import 'angular-resource';
import 'angular-sanitize';
import 'ng-device-detector';
import 'ng-redux';
import 're-tree';
import 'ua-device-detector';
import 'angular-locale-pt-br';

import '@power/power-components/components/power-field/power-field';
import '@power/power-components/components/power-filter-menu/power-filter-menu';
import '@power/power-components/components/power-period/power-period';
import '@power/power-components/components/power-popup/power-popup';
import '@power/power-components/components/power-popup-defaulter-warning/power-popup-defaulter-warning';
import '@power/power-components/components/power-record/power-record';
import '@power/power-components/components/power-report-geofences/power-report-geofences';
import '@power/power-components/components/power-report-fleet-policy/power-report-fleet-policy';
import '@power/power-components/components/power-report-positions/power-report-positions';
import '@power/power-components/components/power-report/power-report';
import '@power/power-components/components/power-report-unitary-violation/power-report-unitary-violation';
import '@power/power-components/components/power-select/power-select';
import '@power/power-components/components/power-toast/power-toast';
import '@power/power-components/components/power-report-utilization/power-report-utilization';
import '@power/power-components/components/power-report-groups/power-report-groups';
import '@power/power-components/components/power-report-wifi/power-report-wifi';
import '@power/power-components/components/power-report-generated-alerts/power-report-generated-alerts';
import '@power/power-components/components/power-report-points-of-interest/power-report-points-of-interest';
import '@power/power-components/components/power-report-travels/power-report-travels';

import './components/golfleet-user-menu/golfleet-user-menu';
import './components/golfleet-dashboard-fuel-module/golfleet-dashboard-fuel-module';
import './components/golfleet-dashboard-speed-limit/golfleet-dashboard-speed-limit';
import './components/golfleet-dashboard/golfleet-dashboard';
import './components/golfleet-dashboard-route/golfleet-dashboard-route';
import './components/golfleet-dashboard-info-addon/golfleet-dashboard-info-addon';
import './components/golfleet-form-fleet-policy/golfleet-form-fleet-policy';
import './components/golfleet-form-tco-accidents-crud/golfleet-form-tco-accidents-crud';
import './components/golfleet-form-revision-plan-crud/golfleet-form-revision-plan-crud';
import './components/golfleet-form-revision-vehicle-crud/golfleet-form-revision-vehicle-crud';
import './components/golfleet-form-checklist/golfleet-form-checklist';
import './components/golfleet-form-driver-crud/golfleet-form-driver-crud';
import './components/golfleet-form-announcement-crud/golfleet-form-announcement-crud';
import './components/golfleet-form-order-service/golfleet-form-order-service';
import './components/golfleet-history-menu/golfleet-history-menu';
import './components/golfleet-issue-tracker/golfleet-issue-tracker';
import './components/power-loader/power-loader';
import './components/golfleet-login/golfleet-login';
import './components/golfleet-navigation-menu/golfleet-navigation-menu';
import './components/golfleet-record-vehicle/golfleet-record-vehicle';
import './components/golfleet-record-driver/golfleet-record-driver';
import './components/golfleet-report-alerts/golfleet-report-alerts';
import './components/golfleet-report-consumption-by-segment/golfleet-report-consumption-by-segment';
import './components/golfleet-report-drivers/golfleet-report-drivers';
import './components/golfleet-report-fuel-supply-management/golfleet-report-fuel-supply-management';
import './components/golfleet-popup-privacy-policy/golfleet-popup-privacy-policy';

import './components/golfleet-report-golcam/golfleet-report-golcam';
import './components/golfleet-report-ranking-details-speed-limit/golfleet-report-ranking-details-speed-limit';
import './components/golfleet-report-ranking-speed-limit/golfleet-report-ranking-speed-limit';
import './components/golfleet-report-tco-cost/golfleet-report-tco-cost';
import './components/golfleet-report-tco-entry/golfleet-report-tco-entry';
import './components/golfleet-report-reports/golfleet-report-reports';
import './components/golfleet-report-reports-tips/golfleet-report-reports-tips';

import './components/golfleet-report-tco-accident/golfleet-report-tco-accident';
import './components/golfleet-report-tco-ticket/golfleet-report-tco-ticket';
import './components/golfleet-report-tco-rent/golfleet-report-tco-rent';
import './components/golfleet-report-tco-parking/golfleet-report-tco-parking';
import './components/golfleet-report-tco-toll/golfleet-report-tco-toll';
import './components/golfleet-report-temp-utilization/golfleet-report-temp-utilization';
import './components/golfleet-report-vehicles/golfleet-report-vehicles';
import './components/golfleet-report-tracking-real-time/golfleet-report-tracking-real-time';
import './components/golfleet-report-temp-violation/golfleet-report-temp-violation';
import './components/golfleet-report-base/golfleet-report-base';
import './components/golfleet-report-revision-plan/golfleet-report-revision-plan';
import './components/golfleet-report-revision-status/golfleet-report-revision-status';
import './components/golfleet-report-revision-history/golfleet-report-revision-history';
import './components/golfleet-report-suspected-accident/golfleet-report-suspected-accident';
import './components/golfleet-report-workshops/golfleet-report-workshops';
import './components/golfleet-report-violation/golfleet-report-violation';
import './components/golfleet-report-announcements/golfleet-report-announcements';
import './components/golfleet-report-motion-log/golfleet-report-motion-log';
import './components/golfleet-report-order-service/golfleet-report-order-service';
import './components/golfleet-report-requester/golfleet-report-requester';
import './components/golfleet-report-visit/golfleet-report-visit';
import './components/golfleet-dashboard-announcements/golfleet-dashboard-announcements';
import './components/golfleet-programmed-block/golfleet-programmed-block';
import './components/golfleet-report-drivers-app-status/golfleet-report-drivers-app-status';
import './components/golfleet-report-blockages/golfleet-report-blockages';
import './components/golfleet-report-checklist/golfleet-report-checklist';
import './components/golfleet-report-drivers-gid-historic/golfleet-report-drivers-gid-historic';
import './components/golfleet-report-fleet-policy-status/golfleet-report-fleet-policy-status';
import './components/golfleet-temperature-sensor/golfleet-temperature-sensor';
// Golfleet Smart
import './components/golfleet-smart-report-vehicles/golfleet-smart-report-vehicles';
import './components/golfleet-smart-report-positions/golfleet-smart-report-positions';
import './components/golfleet-smart-record-vehicle/golfleet-smart-record-vehicle';
// Golfleet Flex
import './components/golfleet-flex-report-vehicles/golfleet-flex-report-vehicles';
import './components/golfleet-flex-record-vehicle/golfleet-flex-record-vehicle';
import './components/golfleet-flex-report-travels/golfleet-flex-report-travels';
import './components/golfleet-flex-report-utilization/golfleet-flex-report-utilization';
import './components/golfleet-flex-report-alerts/golfleet-flex-report-alerts';
import './components/golfleet-flex-report-unitary-violation/golfleet-flex-report-unitary-violation';
import './components/golfleet-flex-dashboard-fuel-module/golfleet-flex-dashboard-fuel-module';
import './components/golfleet-flex-dashboard-speed-limit/golfleet-flex-dashboard-speed-limit';
import './components/golfleet-flex-report-ranking-details-speed-limit/golfleet-flex-report-ranking-details-speed-limit';
import './components/golfleet-flex-report-fleet-policy-status/golfleet-flex-report-fleet-policy-status';

export const app = angular
  .module('app', [
    /* Dependencies */
    'ng.deviceDetector',
    'ngAnimate',
    'ngAria',
    'ngMessages',
    'ngRedux',
    'ngResource',
    'ngSanitize',
    'ui.router',
    /* */
    /* Components */
    'power-field',
    'power-filter-menu',
    'power-report-geofences',
    'power-report-fleet-policy',
    'golfleet-report-fleet-policy-status',
    'power-report-positions',
    'power-report-groups',
    'power-report',
    'power-report-unitary-violation',
    'power-report-utilization',
    'power-select',
    'power-toast',
    'power-loader',
    'power-period',
    'power-popup',
    'power-popup-defaulter-warning',
    'power-record',
    'power-report-wifi',
    'power-report-generated-alerts',
    'power-report-points-of-interest',
    'power-report-travels',
    'golfleet-popup-privacy-policy',
    'golfleet-user-menu',
    'golfleet-dashboard-fuel-module',
    'golfleet-dashboard-speed-limit',
    'golfleet-dashboard',
    'golfleet-dashboard-announcements',
    'golfleet-form-fleet-policy',
    'golfleet-form-tco-accidents-crud',
    'golfleet-form-revision-plan-crud',
    'golfleet-form-revision-vehicle-crud',
    'golfleet-form-checklist',
    'golfleet-form-driver-crud',
    'golfleet-form-announcement-crud',
    'golfleet-form-order-service',
    'golfleet-history-menu',
    'golfleet-issue-tracker',
    'golfleet-login',
    'golfleet-navigation-menu',
    'golfleet-record-vehicle',
    'golfleet-record-driver',
    'golfleet-report-alerts',
    'golfleet-report-consumption-by-segment',
    'golfleet-report-drivers',
    'golfleet-report-fuel-supply-management',
    'golfleet-report-golcam',
    'golfleet-report-ranking-details-speed-limit',
    'golfleet-report-ranking-speed-limit',
    'golfleet-report-tco-cost',
    'golfleet-report-tco-entry',
    'golfleet-report-reports',
    'golfleet-report-reports-tips',
    'golfleet-report-tco-accident',
    'golfleet-report-tco-ticket',
    'golfleet-report-tco-rent',
    'golfleet-report-tco-parking',
    'golfleet-report-tco-toll',
    'golfleet-report-temp-utilization',
    'golfleet-report-vehicles',
    'golfleet-report-tracking-real-time',
    'golfleet-report-base',
    'golfleet-report-revision-plan',
    'golfleet-report-revision-status',
    'golfleet-report-revision-history',
    'golfleet-report-suspected-accident',
    'golfleet-report-workshops',
    'golfleet-report-temp-violation',
    'golfleet-report-violation',
    'golfleet-report-announcements',
    'golfleet-report-visit',
    'golfleet-report-motion-log',
    'golfleet-report-order-service',
    'golfleet-report-requester',
    'golfleet-dashboard-info-addon',
    'golfleet-dashboard-route',
    'golfleet-programmed-block',
    'golfleet-report-drivers-app-status',
    'golfleet-report-blockages',
    'golfleet-report-checklist',
    'golfleet-report-drivers-gid-historic',
    // Golfleet Smart
    'golfleet-smart-report-vehicles',
    'golfleet-smart-report-positions',
    'golfleet-smart-record-vehicle',
    'golfleet-smart-record-vehicle',
    // Golfleet Flex
    'golfleet-flex-report-vehicles',
    'golfleet-flex-record-vehicle',
    'golfleet-flex-report-travels',
    'golfleet-flex-report-utilization',
    'golfleet-flex-report-alerts',
    'golfleet-flex-report-unitary-violation',
    'golfleet-flex-dashboard-fuel-module',
    'golfleet-flex-dashboard-speed-limit',
    'golfleet-flex-report-ranking-details-speed-limit',
    'golfleet-flex-report-fleet-policy-status',
    /* */
  ])
  .config([
    '$httpProvider',
    $httpProvider => {
      $httpProvider.interceptors.push('authInterceptorService');
      $httpProvider.defaults.headers.common.Accept =
        'application/json, text/javascript, text/html, image/svg+xml';
      $httpProvider.defaults.headers.common['Content-Type'] =
        'application/json; text/html; image/svg+xml; charset=utf-8';
      $httpProvider.defaults.useXDomain = true;
      delete $httpProvider.defaults.headers.common['X-Requested-With'];
    },
  ]);
