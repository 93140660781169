/* eslint new-cap: ["error", { "newIsCap": false }] */
import angular from 'angular';
import 'angular-drag-and-drop-lists';

import '@power/power-components/components/power-fab/power-fab';
import '@power/power-components/components/power-toolbar-report/power-toolbar-report';
import '@power/power-components/components/power-dropdown/power-dropdown';
import '@power/power-components/components/power-footer/power-footer';
import '@power/power-components/components/power-pagination/power-pagination';
import '@power/power-components/components/power-crud/power-crud';
import '@power/power-components/components/power-popup/power-popup';
import '@power/power-components/components/power-popup-share/power-popup-share';
import '@power/power-components/components/power-popup-delete/power-popup-delete';
import '@power/power-components/components/power-toast/power-toast';
import '@power/power-components/components/power-grid-fleet-policy-status/power-grid-fleet-policy-status';
import '@power/power-components/components/power-popup-move-vehicles/power-popup-move-vehicles';
import { GolfleetReportFleetPolicyStatusController } from '../golfleet-report-fleet-policy-status/golfleet-report-fleet-policy-status';

import template from './golfleet-flex-report-fleet-policy-status.html';
import './golfleet-flex-report-fleet-policy-status.scss';

class GolfleetFlexReportFleetPolicyStatusController extends GolfleetReportFleetPolicyStatusController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
      'recordServices',
    ];
  }

  /**
   * Creates an instance of ReportFleetPolicyStatusController.
   * @memberof ReportFleetPolicyStatusController
   */
  // eslint-disable-next-line no-useless-constructor
  constructor(
    $element,
    $scope,
    $ngRedux,
    $http,
    $state,
    $timeout,
    urlApi,
    commonServices,
    filterServices,
    reportServices,
    recordServices,
  ) {
    super(
      $element,
      $scope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
      recordServices,
    );

    this.tabs = [{ name: 'rules', label: 'Regras', selected: true }];
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();
  }

  $onDestroy() {
    super.$onDestroy();
  }
  /* */

  /* Public */
  /* */

  /* Private */
  _eventModalReadOnlyFleetPoliciesSwitch() {
    this.$.querySelector(
      'golfleet-flex-report-fleet-policy-status > #popup-readonly-fleet-policies',
    ).toggle();
  }
  /* */

  /* Observers */
  /* */
}

class GolfleetFlexReportFleetPolicyStatus {
  /**
   * Creates an instance of ReportFleetPolicyStatus.
   * @memberof ReportFleetPolicyStatus
   */
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetFlexReportFleetPolicyStatusController;
  }
}

angular
  .module('golfleet-flex-report-fleet-policy-status', [
    'power-fab',
    'power-toolbar-report',
    'power-dropdown',
    'power-grid-fleet-policy-status',
    'power-footer',
    'power-pagination',
    'power-popup',
    'power-crud',
    'power-popup-share',
    'power-popup-delete',
    'power-toast',
    'power-popup-move-vehicles',
    'dndLists',
  ])
  .component('golfleetFlexReportFleetPolicyStatus', new GolfleetFlexReportFleetPolicyStatus());
